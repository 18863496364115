'use client'
import { useParams, usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';

export const ScrollToTop = () => {
    const [showButton, setShowButton] = useState<boolean>(false);

    useEffect(() => {
        const handleButtonShow = () => {
            window.scrollY > 200 ? setShowButton(true) : setShowButton(false);
        }

        window.addEventListener('scroll', handleButtonShow);
        
        // cleaning up the event listner when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleButtonShow);
        }
    }, [])    

    const scrollToTop = () => {
        window.scrollTo({top:0, behavior:"smooth"});
    }

    return (
        showButton &&
        <div className='sticky bottom-0'>
            <div className='relative'>
                <button className="absolute bottom-5 right-5 bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 rounded-full ease-in-out transition duration-150 shadow-lg leading-tight" id="scroll-to-top" onClick={scrollToTop}>
                    <i data-icon="--arrow-up-icon" className="arrow-up-icon" />
                </button>
            </div>
        </div>
    );
}