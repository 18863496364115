/* eslint-disable @next/next/no-sync-scripts */
'use client'

import { useUserInformation } from '@/contexts/UserProvider';
import Script from 'next/script';
import React from 'react';

export const JiraIssueCollector = () => {
    const { isAcquian } = useUserInformation()
    if (!isAcquian) {
      return <></>
    }
    return (
        <Script src='https://acquia.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/tod1zk/b/5/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=5be2dbf2'></Script>
    );
}