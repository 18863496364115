import type { Config } from 'tailwindcss'

const config: Config = {
  darkMode: 'class',
  content: [
    './pages/**/*.{js,ts,jsx,tsx,mdx}',
    './components/**/*.{js,ts,jsx,tsx,mdx}',
    './app/**/*.{js,ts,jsx,tsx,mdx}',
    './contexts/**/*.{js,ts,jsx,tsx,mdx}',
    './stories/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  theme: {
    extend: {
      skew: {
        '20': '20deg'
      },
      transitionProperty: {
        'width': 'width',
      },
      backgroundImage: {
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
        'gradient-conic': 'conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))',
      },
      fontFamily: {
        'display': '"Sharp Sans", "Helvetica Neue","Helvetica","Roboto","Arial", sans-serif',
        'main': '"Proxima Nova", "Helvetica Neue","Helvetica","Roboto","Arial"',
        //'thin': '"Proxima Nova", "Helvetica Neue","Helvetica","Roboto","Arial"',
      },
      maxWidth: {
        'md-2/3-col': '31.75rem',
        'md-3-col': '15.5rem',
        'lg-4-col': '15.25rem',
        'xl-4-col': '19.438rem',
        '2xl-4-col': '23.25rem',
        '2xl-2-col': '47.5rem',
        'xl-2-col': '39.625rem',
        'lg-2-col': '31.5rem',
        'md-2-col': '23.625rem',
        'sm-2-col': '19.5rem',
      },
      colors: {
        navy: {
          800: "#141938",
          700: "#121B50",
          600: "#232C61",
          500: "#797F9F",
          400: "#A6AABF",
          300: "#EBEEFF"
        },
        black: {
          DEFAULT: "#141A1F",
        }
      }
    },
    colors: {
      blue: {
          800: "#036BB5",
          700: "#1A89C9",
          600: "#26A3DD",
          500: "#70C3E9",
          400: "#D3ECF8",
          300: "#F2F9FC"
      },
      pink: {
          800: "#BD0A50",
          700: "#D90C5B",
          600: "#E1126E",
          500: "#F8A0C7",
          400: "#FBD0E3",
          300: "#FFEFF6"
      },
      orange: {
          800: "#D14900",
          700: "#E05812",
          600: "#F47A20",
          500: "#F8A86D",
          400: "#FAC59E",
          300: "#FDE2CE"
      },
      teal: {
          800: "#1B8385",
          700: "#45A8AA",
          600: "#66C8CA",
          500: "#A0DDDE",
          400: "#C6EBEB",
          300: "#D9F1F2"
      },
      yellow: {
          800: "#EF9B16",
          700: "#F6AE23",
          600: "#FEC231",
          500: "#FED267",
          400: "#FFE199",
          300: "#FFF0CC"
      },
      gray: {
          800: "#3D4F5C",
          700: "#52697A",
          600: "#C2CED6",
          500: "#E0E6EB",
          400: "#F0F3F5",
          300: "#F9FAFB"
      },
      white: {
        DEFAULT: "#FFFFFF",
      },
      transparent: {
        DEFAULT: 'transparent'
      }
    },
    plugins: [],
  }
}

export default config
