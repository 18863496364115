import { createAppSlice } from "../createAppSlice";
// import type { AppThunk } from "../store";
import type { PayloadAction } from "@reduxjs/toolkit";
import { load, save } from "../utils";

export interface VersionState {
  preferences: Record<string, string>
}

const initialState: VersionState = {
  preferences: {}
};

interface ProductPreference {
  product: string
  version: string
}

export const versions = createAppSlice({
  name: 'versions',
  initialState,
  reducers: (create) => ({
    setVersionPreference: create.reducer(
      (state, action: PayloadAction<ProductPreference>) => {
        state.preferences[action.payload.product] = action.payload.version
        save<VersionState>('versions', state)
      }
    ),
    initialize: create.reducer(
      (state) => {
        state.preferences = load<VersionState>('versions')?.preferences ?? {}
      }
    )
  }),
  selectors: {
    preferences: (state) => state.preferences
  }
})

export const {setVersionPreference} = versions.actions
export const {preferences} = versions.selectors