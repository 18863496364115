'use client'
import { usePathname } from "next/navigation";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";

export type IFrameCompactContext = {
  active?: boolean
}

const IFrameCompactContext = createContext<IFrameCompactContext>({
  active: false,
})

export const useIFrameCompact = () => useContext(IFrameCompactContext);

export const IFrameCompactProvider = (props: IFrameCompactContext & {
  children: ReactNode
}) => {
  const pathname = usePathname()
  const [active, setActive] = useState(props.active ?? false)
  
  useEffect(() => {
    const onLoad = () => {
      setActive(window.location.hash == '#iframe-compact')
    }

    if (document.readyState === "complete") {
      setActive(window.location.hash == '#iframe-compact')
    } else {
      window.addEventListener('load', onLoad);
      
      return () =>  {
        window.removeEventListener('load', onLoad)
      }
    }
  }, [pathname])
  
  return <IFrameCompactContext.Provider value={{ active }}>
    {props.children}
  </IFrameCompactContext.Provider>
}

export const HideOnIFrameCompact = ({ children }: {
  children: ReactNode
}) => {
  const { active } = useIFrameCompact()

  if (active) {
    return <></>
  }
  return <>{children}</>
}